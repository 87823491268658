import React, { useEffect } from 'react'
import { navigate } from 'gatsby'
import CircularProgress from '@material-ui/core/CircularProgress'
import { useConnect } from 'redux-bundler-hook'
import { REGISTRATION_STEPS } from '@src/components/account/Account'
import { localizedUrl } from '@src/utils/navigate'

const BecomeMember = () => {
	const { language, service } = useConnect('selectLanguage', 'selectService')
	useEffect(() => {
		navigate(localizedUrl('/kom-igang', language, service), {
			state: { selectPage: REGISTRATION_STEPS.CONTACT_INFORMATION },
		})
	}, [language, service])

	return <CircularProgress />
}

export default BecomeMember
